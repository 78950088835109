import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import { Text } from '~/design-system/text';
import { useRef } from 'react';

export function FailedMutationAlertDialog({
  title,
  message,
  onClose,
}: {
  title: string;
  message?: string;
  onClose: () => void;
}) {
  const isOpen = !!message?.length;
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text fontSize='sm' color='gray.600'>
              {message?.split('\\n').length === 1 ? (
                message?.split('\\n')[0]
              ) : (
                <UnorderedList>
                  {message?.split('\\n').map((message, idx) => {
                    return <ListItem key={idx}>{message}</ListItem>;
                  })}
                </UnorderedList>
              )}
            </Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} colorScheme='blue' onClick={onClose}>
              Ok
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
