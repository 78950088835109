import { FailedMutationAlertDialog } from '~/design-system/mutation-failed-alert-dialog/FailedMutationAlertDialog';
import { createContext, useCallback, useContext, useState } from 'react';
import type { ReactNode } from 'react';

type AlertContent = { title: string; message?: string };

const GlobalErrorDialogContext = createContext({
  isOpen: false,
  show: (alertContent?: AlertContent) => {},
});

export const GlobalErrorDialogProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [alertContent, setAlertContent] = useState<AlertContent>({
    title: '',
    message: '',
  });

  const show = useCallback((alertContent?: AlertContent) => {
    setAlertContent({
      title: alertContent?.title.length
        ? alertContent.title
        : 'Oops, something went wrong!',
      message: alertContent?.message?.length
        ? alertContent.message
        : `We're not sure what went wrong, please try again. If the issue persists please contact Navace and we'll get right on it.`,
    });
    setIsOpen(true);
  }, []);

  const hide = () => {
    setAlertContent({ title: '', message: '' });
    setIsOpen(false);
  };

  return (
    <GlobalErrorDialogContext.Provider
      value={{
        isOpen,
        show,
      }}
    >
      {children}
      {isOpen && <FailedMutationAlertDialog onClose={hide} {...alertContent} />}
    </GlobalErrorDialogContext.Provider>
  );
};

export const useOpenGlobalErrorDialog = () => {
  const { show } = useContext(GlobalErrorDialogContext);

  const showAlert = useCallback(
    (alertContent?: AlertContent) => {
      show(alertContent);
    },
    [show]
  );

  return showAlert;
};
